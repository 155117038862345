import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-linea-ctd',
  templateUrl: './linea-ctd.component.html',
  styleUrls: ['./linea-ctd.component.sass']
})
export class LineaCtdComponent implements OnInit {

  public file: string = '';
  public ext: string = '';
  public bucket: string = environment.firebase.storageBucket;

  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<LineaCtdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  preview(event: any) {
    if(event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.ext = ((this.file as any).name as string).split('.').reverse()[0];
      this.data.filename = `linea_pago.${this.ext}`;
    }
  }

  uploadFile(flag: boolean) {
    this.data.resp = flag;
    if(flag) {
      let ref = this.storage.refFromURL(`gs://${this.bucket}/lineas-tlalnepantla-aguas/${this.data.folio.replaceAll('/', '-')}/linea_pago.${this.ext}`);
      ref.put(this.file).then((task) => {
        if(task.state == 'success') {
          ref.getDownloadURL().subscribe(r => {
            this.data.url = r;
            this.dialogRef.close(this.data);
          });
        }
      });
    } else {
      this.dialogRef.close(this.data);
    }
  }

}
