import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpdmService } from 'src/app/private/services/opdm.service';
import { TramitesService } from 'src/app/private/services/tramites.service';
import { environment } from 'src/environments/environment';
import { RefreshComponent } from '../refresh/refresh.component';

@Component({
  selector: 'app-recibo-oficial',
  templateUrl: './recibo-oficial.component.html',
  styleUrls: ['./recibo-oficial.component.sass']
})
export class ReciboOficialComponent implements OnInit {

  form_pago: FormGroup = this.fb.group({
    folio_externo: [ null, [Validators.required] ],
    campo2: [ null, [] ],
    estatus: [ null, [] ],
    fecha: [ null, [Validators.required] ],
    info: [ null, [] ],
    serie: [ null, [Validators.required] ],
    identificador: [ null, [Validators.required] ],
    banco: [ null, [Validators.required] ],
  });

  form_catastro: FormGroup = this.fb.group({
    folio_externo: [ null, [Validators.required] ],
    clave_catastral: [ null, [Validators.required] ],
    domicilio_comp: [ null, [Validators.required] ],
    clave_catastral_anterior: [ null, [Validators.required] ],
    calle: [ null, [Validators.required] ],
    lote: [ null, [Validators.required] ],
    manzana: [ null, [Validators.required] ],
    numero_exterior: [ null, [Validators.required] ],
    numero_interior: [ null, [Validators.required] ],
    colonia: [ null, [Validators.required] ],
    codigo_postal: [ null, [Validators.required] ],
    bimestre_vigencia: [ null, [Validators.required] ],
    anio_vigencia: [ null, [Validators.required] ],
    propietario: [ null, [Validators.required] ],
    superficie_terreno_p: [ null, [Validators.required] ],
    superficie_construccion_p: [ null, [Validators.required] ],
    valor_terreno_p: [ null, [Validators.required] ],
    valor_construccion_p: [ null, [Validators.required] ],
    superficie_terreno_comun: [ null, [Validators.required] ],
    superficie_construccion_comun: [ null, [Validators.required] ],
    valor_terreno_comun: [ null, [Validators.required] ],
    valor_construccion_comun: [ null, [Validators.required] ],
    total: [ null, [Validators.required] ],
  });

  public file: any;
  public ext: string = '';
  public bucket: string = environment.firebase.storageBucket;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private tService: TramitesService,
    private opdmService: OpdmService,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<ReciboOficialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getFolio();
  }

  preview(event: any) {
    if(event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.ext = ((this.file as any).name as string).split('.').reverse()[0];
      this.data.filename = `recibo_oficial.${this.ext}`;
    }
  }

  uploadFile(flag: boolean) {
    this.data.resp = flag;
    if(flag) {
      if(this.file) {
        this.tService.saveCatastro({ predio: this.form_catastro.value, pago: this.form_pago.value }).subscribe({
          next: (resp) => {
            let ref = this.storage.refFromURL(`gs://${this.bucket}/recibo-aguas/${this.data.folio.replaceAll('/', '-')}/recibo_oficial.${this.ext}`);
            ref.put(this.file).then((task) => {
              if(task.state == 'success') {
                ref.getDownloadURL().subscribe(r => {
                  this.data.url = r;
                  this.dialogRef.close(this.data);
                });
              }
            });
          },
          complete: () => {},
          error: (err) => {
            if(err.error.message == 'Invalid Token') {
              this.refresh();
            }
          }
        });
      }
    } else {
      this.dialogRef.close(this.data);
    }
  }

  getFolio() {
    this.tService.obtenerArchivos(this.data.folio).subscribe({
      next: (r) => {
        this.opdmService.obtener_info_cliente(r.request.predio).subscribe({
          next: (respuesta) => {
            let obj = this.opdmService.obtener_info_cliente_transform(respuesta);
            let cp: string = obj.domicilio.split(',').find((x: string) => x.includes('C.P.'));
            let vigencia = obj.ultimo_periodo.split('-');
            this.form_catastro.get('folio_externo')?.setValue(r.request.folio_externo);
            this.form_pago.get('folio_externo')?.setValue(r.request.folio_externo);
            this.form_catastro.get('clave_catastral')?.setValue(r.request.catastro_numero ?? '----');
            this.form_catastro.get('domicilio_comp')?.setValue(obj.domicilio);
            this.form_catastro.get('calle')?.setValue(r.request.calle ?? '----');
            this.form_catastro.get('numero_exterior')?.setValue(r.request.numero_exterior ?? r.request.predio_no_exterior ?? '----');
            this.form_catastro.get('numero_interior')?.setValue(r.request.numero_interior ?? r.request.predio_no_interior ?? '----');
            this.form_catastro.get('lote')?.setValue(obj.tipo_de_toma ?? '----');
            this.form_catastro.get('manzana')?.setValue(r.request.manzana ?? '----');
            this.form_catastro.get('colonia')?.setValue(obj.colonia ?? '----');
            this.form_catastro.get('propietario')?.setValue(obj.nombre ?? '----');

            this.form_catastro.get('clave_catastral_anterior')?.setValue(r.request.lote);
            this.form_catastro.get('codigo_postal')?.setValue(cp.split(' ').reverse()[0]);
            this.form_catastro.get('bimestre_vigencia')?.setValue(vigencia[1]);
            this.form_catastro.get('anio_vigencia')?.setValue(parseInt(vigencia[2]) < 2022 ? 2022 : parseInt(vigencia[2]));
            this.form_catastro.get('superficie_terreno_p')?.setValue(0);
            this.form_catastro.get('superficie_construccion_p')?.setValue(0);
            this.form_catastro.get('valor_terreno_p')?.setValue(0);
            this.form_catastro.get('valor_construccion_p')?.setValue(0);
            this.form_catastro.get('superficie_terreno_comun')?.setValue(0);
            this.form_catastro.get('superficie_construccion_comun')?.setValue(0);
            this.form_catastro.get('valor_terreno_comun')?.setValue(0);
            this.form_catastro.get('valor_construccion_comun')?.setValue(0);
            this.form_catastro.get('total')?.setValue(0);

            this.form_pago.get('fecha')?.setValue(new Date());
          }
        });
      },
      complete: () => {},
      error: (err) => {
        if(err.error.message == 'Invalid Token') {
          this.refresh();
        }
      }
    });
  }

  refresh() {
    this.dialog.open(RefreshComponent, { disableClose: true, width: '600px' });
  }

}
