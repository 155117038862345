<h1 mat-dialog-title>Agregar anotacion</h1>
<div mat-dialog-content>
  <p>Escribe la nota a continuacion.</p>
  <mat-form-field appearance="fill">
    <mat-label>Nota</mat-label>
    <input matInput [(ngModel)]="data.note">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close(false)">Descartar</button>
  <button mat-button (click)="close(true)" cdkFocusInitial>Agregar</button>
</div>