<h1 mat-dialog-title>Alerta</h1>
<div mat-dialog-content>
  <span>¿Estas seguro de realizar esta accion?</span>
  <p>Ingresa el folio que se asignará a este trámite.</p>
  <form class="form" [formGroup]="form_folio">
    <mat-form-field appearance="fill" color="accent" class="w-100">
      <mat-label>Folio</mat-label>
      <input type="text" matInput formControlName="folio">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close(false)">Cancelar</button>
  <button mat-button (click)="close(true)" [disabled]="form_folio.invalid" cdkFocusInitial>Continuar</button>
</div>
