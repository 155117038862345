<h1 mat-dialog-title>Subir recibo oficial de pago</h1>
<div mat-dialog-content>
  <p>Elige el recibo de pago. Al dar clic en el boton <b>subir</b>, el documento se enviará<br/>definitivamente.</p><hr>
  <div class="w-100 pb-3">
    <input class="form-control" type="file" (change)="preview($event)">
  </div>
  <form [formGroup]="form_pago">
    <p>Ingresa los datos de recibo.</p><hr>
    <div class="w-100 pb-3 form">
      <mat-form-field appearance="fill">
        <mat-label>serie</mat-label>
        <input type="text" formControlName="serie" matInput>
      </mat-form-field><mat-form-field appearance="fill">
        <mat-label>identificador / folio</mat-label>
        <input type="text" formControlName="identificador" matInput>
      </mat-form-field><mat-form-field appearance="fill">
        <mat-label>banco</mat-label>
        <input type="text" formControlName="banco" matInput>
      </mat-form-field><mat-form-field appearance="fill">
        <mat-label>fecha</mat-label>
        <input type="date" formControlName="fecha" matInput>
      </mat-form-field>
    </div>
  </form>
  <form [formGroup]="form_catastro">
    <p>Ingresa los datos que apareceran en la certificación.</p><hr>
    <div class="w-100 pb-3 form">
      <mat-form-field appearance="fill">
        <mat-label>Clave de aguas</mat-label>
        <input type="text" formControlName="clave_catastral_anterior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Clave catastral</mat-label>
        <input type="text" formControlName="clave_catastral" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Domicilio Completo</mat-label>
        <input type="text" formControlName="domicilio_comp" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Calle</mat-label>
        <input type="text" formControlName="calle" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Tipo de toma</mat-label>
        <input type="text" formControlName="lote" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Manzana</mat-label>
        <input type="text" formControlName="manzana" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Número Exterior</mat-label>
        <input type="text" formControlName="numero_exterior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Número Interior</mat-label>
        <input type="text" formControlName="numero_interior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Colonia</mat-label>
        <input type="text" formControlName="colonia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Código Postal</mat-label>
        <input type="text" minlength="5" maxlength="5" formControlName="codigo_postal" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Bimestre Vigencia</mat-label>
        <input type="number" min="1" max="6" formControlName="bimestre_vigencia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Año Vigencia</mat-label>
        <input type="number" min="2022" max="3000" formControlName="anio_vigencia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Propietario</mat-label>
        <input type="text" formControlName="propietario" matInput>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="uploadFile(false)">Cancelar</button>
  <button mat-button [disabled]="form_catastro.invalid || form_pago.invalid" (click)="uploadFile(true)" cdkFocusInitial>Subir</button>
</div>
