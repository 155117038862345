<h1 mat-dialog-title>Subir línea de pago</h1>
<div mat-dialog-content>
  <p>Elige el documento con la línea de pago.<br/>Al dar clic en el boton <b>subir</b>, el documento se enviará<br/>definitivamente.</p>
  <div class="w-100 pb-3">
    <input class="form-control" type="file" (change)="preview($event)">
  </div>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>Número de línea de pago</mat-label>
    <input matInput [(ngModel)]="data.number_capture">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="uploadFile(false)">Cancelar</button>
  <button mat-button (click)="uploadFile(true)" cdkFocusInitial>Subir</button>
</div>