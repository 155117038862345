import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OpdmService {

  private url: string = 'https://www.pagaenlinea.com.mx/api/OtrasFunciones';

  constructor(
    private http: HttpClient
  ) { }

  obtener_info_cliente(predio?: string, cuenta?: string) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};150;${predio ?? ''};${cuenta ?? ''}`, {});
  }

  generar_nuevo_tramite(predio: string, comentario: string, solicitante: string) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};151;${predio};${comentario};${solicitante}`, {});
  }

  consultar_estatus_tramite(tramite: number) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};152;${tramite}`, {});
  }

  consultar_estatus_presupuesto(presupuesto: number) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};153;${presupuesto}`, {});
  }

  registro_finalizacion_tramite(tramite: number, parametro: string) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};154;${tramite};${parametro}`, {});
  }

  cancelacion_tramite(tramite: number, comentario?: string) {
    const endPoint = `${this.url}/36`;
    return this.http.get<string>(`${endPoint};155;${tramite};${comentario ?? ''}`, {});
  }

  obtener_info_cliente_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
    case -413:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.msg = "";
      respuesta.predio = lista[1] == "" ? null : lista[1];
      respuesta.cuenta = lista[2] == "" ? null : lista[2];
      respuesta.nombre = lista[3] == "" ? null : lista[3];
      respuesta.domicilio = lista[4] == "" ? null : lista[4];
      respuesta.colonia = lista[5] == "" ? null : lista[5];
      respuesta.periodos_de_adeudo = lista[6] == "" ? null : lista[6];
      respuesta.ultimo_periodo = lista[7] == "" ? null : lista[7];
      respuesta.fecha_vencimiento = lista[8] == "" ? null : this.fecha(lista[8]);
      respuesta.tipo_de_toma = lista[9] == "" ? null : lista[9];
      respuesta.folio_de_inspeccion = lista[10] == "" ? null : lista[10];
      respuesta.fecha_de_solicitud_de_inspeccion = lista[11] == "" ? null : this.fecha(lista[11]);
      respuesta.estatus_de_inspeccion = lista[12] == "" ? null : lista[12];
      respuesta.resultado_de_inspeccion = lista[13] == "" ? null : lista[13];
      respuesta.folio_de_orden = lista[14] == "" ? null : lista[14];
      respuesta.fecha_de_solicitud_de_orden = lista[15] == "" ? null : this.fecha(lista[15]);
      respuesta.estatus_de_orden = lista[16] == "" ? null : lista[16];
      respuesta.resultado_de_orden = lista[17] == "" ? null : lista[17];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  generar_nuevo_tramite_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
    case -413:
    case -414:
    case -415:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.msg = lista[3];
      respuesta.numero_de_tramite = lista[1] == "" ? null : lista[1];
      respuesta.tipo_de_tramite = lista[2] == "" ? null : lista[2];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  consultar_estatus_tramite_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case -413:
    case -414:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.msg = lista[2];
      respuesta.estatus_del_tramite = lista[1] == "" ? null : lista[1];
      respuesta.numero_de_presupuesto = lista[3] == "" ? null : lista[3];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  consultar_estatus_presupuesto_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case -413:
    case -414:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.estatus_del_presupuesto = lista[1] == "" ? null : lista[1];
      respuesta.msg = lista[2];
      respuesta.numero_de_presupuesto = lista[3] == "" ? null : lista[3];
      respuesta.monto = lista[4] == "" ? null : lista[4];
      respuesta.vencimiento = lista[5] == "" ? null : lista[5];
      respuesta.url_de_pago = lista[6] == "" ? null : lista[6];
      respuesta.referencia = lista[7] == "" ? null : lista[7];
      respuesta.banco = lista[8] == "" ? null : lista[8];
      respuesta.fecha_de_pago = lista[9] == "" ? null : this.fecha(lista[9]);
      respuesta.numero_de_recibo = lista[10] == "" ? null : lista[10];
      respuesta.serie = lista[11] == "" ? null : lista[11];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  registro_finalizacion_tramite_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case -413:
    case -414:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  cancelacion_tramite_transform(data: string) {
    let lista = data.split(';');
    let respuesta: any = {};
    switch(parseInt(lista[0])) {
    case 0:
    case -410:
    case -411:
    case -412:
      respuesta.ok = false;
      respuesta.msg = lista[1];
      break;
    case -413:
    case -414:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    case 1:
      respuesta.ok = true;
      respuesta.msg = lista[1];
      break;
    default:
      respuesta.ok = false;
      respuesta.msg = lista;
      break;
    }
    return respuesta;
  }

  fecha(date: string): Date {
    let mes: Map<string, number> = new Map();
    mes.set("ENE", 0);
    mes.set("FEB", 1);
    mes.set("MAR", 2);
    mes.set("ABR", 3);
    mes.set("MAY", 4);
    mes.set("JUN", 5);
    mes.set("JUL", 6);
    mes.set("AGO", 7);
    mes.set("SEP", 8);
    mes.set("OCT", 9);
    mes.set("NOV", 10);
    mes.set("DIC", 11);
    let fecha = date.split('/');
    return new Date(parseInt(fecha[2]), mes.get(fecha[1]) ?? 0, parseInt(fecha[0]));
  }

}
