// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: "fxih0ee6a8e9fhNPd1d2407d0",
  CRYPTO_KEY: "jc9ab428ad36458d2255dbda3dbe593fb3f6b32be921cb37c9db53f02cd2aa",
  JWT_KEY: "2578f7e4310d9cbf4f81309baf0556847dd115e56a51f752add494763bac0c29",
  url: "https://api-tlalnepantla-aguas.zurco.tech",
  url_notarios: 'https://notarios-api.zurco.com.mx/api/v2/notarios',
  user_notarios: 'usertest',
  pass_notarios: '12345678',
  api_key_notarios: '63bc29ee6a8e9fhNd1d2407d0ac0',
  firebase: {
    apiKey: "AIzaSyCxhl-Jesv3EHAUBKThp4i53MYLZ21rHq8",
    authDomain: "opdm-qa.firebaseapp.com",
    projectId: "opdm-qa",
    storageBucket: "opdm-qa.appspot.com",
    messagingSenderId: "786554769291",
    appId: "1:786554769291:web:61adecd3cd38b3d70e698e",
    measurementId: "G-VMYYZZQXL1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
